.article-mini {
  margin-top: 5px;

  &__img {
    width: 100%;
  }

  &__title {
    font-size: 1.6em;
    line-height: 1.35em;
    font-weight: 600;
    color: #fff;
    margin: -3px 0 0.5em;
  }
  &__description {
  }
}
