.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.ant-layout {
  min-height: 100vh;
}

.header-menu {
  display: flex;
}

@media (min-width: 768px) {
  .header-menu__right {
    flex-grow: 1;
    text-align: right;
  }
}

@media (max-width: 768px) {
  .ant-layout-header {
    padding-left: 10px;
    padding-right: 10px;
  }
  .hide-on-mobile {
    display: none;
  }
  .ant-card-body,
  .ant-modal-body {
    padding: 10px;
  }
}

.responsive-container {
  height: 100%;
  width: 100%;
}

body {
  font-family: "Roboto", sans-serif;
}

.separator {
  width: 90%;
  height: 8px;
  background: #a1334e;
  margin: 22px 0;
  border: none;
  outline: 0;

  @media (max-width: 480px) {
    height: 5px;
    margin: 10px 0 15px;
  }
}

.hide-mobile {
  @media (max-width: 480px) {
    display: none;
  }
}

.white-svg .ant-spin-dot-item {
  background-color: #fff;
}

hr {
  margin: 20px 0px;
  border-bottom: none;
  border-top: 1px solid #aaa;
}
