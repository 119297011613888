.news-list {
  color: #fff;
  height: 100%;

  &__title {
    display: block;
    font-size: 2.8em;
    line-height: 1.17em;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 0.8em;
    opacity: 0.9;
  }
}
