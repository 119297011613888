.social-conflict-stats {
  color: #fff;

  &__main-number {
    display: block;
    font-size: 6.4rem;
    font-weight: 900;
    line-height: 0.88em;

    @media (max-width: 480px) {
      font-size: 6em;
    }
  }

  &__main-label {
    font-weight: 900;
    font-size: 3.6em;
    line-height: 1.16em;
    text-transform: uppercase;
    opacity: 0.9;

    @media (max-width: 480px) {
      font-size: 2.2em;
    }
  }
}
