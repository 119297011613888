.map-popup {
  & .mapboxgl-popup-content {
    background: #a1334e;
    border-radius: 10px;
  }
  &__inner {
    color: #fff;

    width: 500px;

    @media (max-width: 480px) {
      width: 90vw;
    }

    & p {
      font-size: 1.4em;
      line-height: 1.21em;
      color: #d8d8d8;
    }
  }
  &__title {
    font-size: 1.8em;
    line-height: 1.22em;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    padding-right: 3em;
  }
  &__related-title {
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.4em;
  }
  &__related-list {
    padding: 0;

    & li {
      list-style: none;
      font-size: 1.4em;
      line-height: 1.21em;
      color: #fff;
      padding-left: 1.1em;
      position: relative;

      &::before {
        content: "▶";
        text-decoration: none;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    & li a {
      color: inherit;
      text-decoration: underline;
    }
  }

  & .mapboxgl-popup-close-button {
    color: #fff;
    font-size: 3em;
    line-height: 1em;
    top: 5px;
    right: 4px;
  }

  & .mapboxgl-popup-tip {
    border-bottom-color: #a1334e !important;
  }
}
